import React from 'react';
import { Link } from 'react-scroll';

const Hero = () => {
  return (
    <div className="relative bg-gray-900 h-screen">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-r from-gray-900 to-gray-800 mix-blend-multiply" />
      </div>
      <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
          Innovative Software Solutions
        </h1>
        <p className="mt-6 text-xl text-gray-300 max-w-3xl">
          Transforming ideas into powerful digital solutions. We specialize in custom software development,
          mobile applications, and enterprise solutions that drive business growth.
        </p>
        <div className="mt-10">
          <Link
            to="contact"
            smooth={true}
            duration={500}
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 cursor-pointer"
          >
            Get Started
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Hero;
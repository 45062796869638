import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import collaborateImage from '../assets/images/collaborate.svg';
import innovationImage from '../assets/images/innovation.svg';
import teamImage from '../assets/images/team.svg';

function WhatWeDo() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const features = [
    {
      title: 'Custom Software Development',
      description: 'We build tailored solutions that perfectly align with your business needs and goals.',
      image: collaborateImage,
      stats: [],
    },
    {
      title: 'Innovation & Technology',
      description: 'Leveraging cutting-edge technologies to create future-proof solutions for your business.',
      image: innovationImage,
      stats: [],
    },
    {
      title: 'Expert Team',
      description: 'Our experienced team brings diverse skills and deep expertise to every project.',
      image: teamImage,
      stats: [],
    },
  ];

  return (
    <section id="what-we-do" className="py-24 bg-gradient-to-b from-gray-50 to-white overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          transition={{ duration: 0.6 }}
          className="text-center"
        >
          <h2 className="text-4xl font-bold text-gray-900 sm:text-5xl">
            What We Do
          </h2>
          <p className="mt-6 text-xl text-gray-600 max-w-3xl mx-auto">
            We combine technical expertise with creative problem-solving to deliver 
            innovative solutions that drive business growth and success.
          </p>
        </motion.div>

        <div className="mt-20 space-y-24">
          {features.map((feature, index) => (
            <motion.div
              key={feature.title}
              initial={{ opacity: 0, y: 50 }}
              animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
              className={`relative ${
                index % 2 === 0 ? 'lg:flex-row' : 'lg:flex-row-reverse'
              } lg:flex lg:items-center lg:space-x-8 lg:space-x-reverse`}
            >
              <div className="relative lg:w-1/2">
                <motion.div
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={inView ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.8 }}
                  transition={{ duration: 0.6, delay: index * 0.2 + 0.3 }}
                  className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0"
                >
                  <div className="pt-12 sm:pt-16 lg:pt-20">
                    <h3 className="text-3xl font-bold text-gray-900 tracking-tight sm:text-4xl">
                      {feature.title}
                    </h3>
                    <div className="mt-6 text-gray-500 space-y-6">
                      <p className="text-lg">
                        {feature.description}
                      </p>
                      <div className="grid grid-cols-3 gap-4 mt-8">
                        {feature.stats.map((stat, statIndex) => (
                          <motion.div
                            key={stat}
                            initial={{ opacity: 0, y: 20 }}
                            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                            transition={{ duration: 0.6, delay: index * 0.2 + statIndex * 0.1 }}
                            className="text-center"
                          >
                            <p className="text-sm font-medium text-blue-600">{stat}</p>
                          </motion.div>
                        ))}
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>

              <motion.div
                initial={{ opacity: 0, x: index % 2 === 0 ? 50 : -50 }}
                animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: index % 2 === 0 ? 50 : -50 }}
                transition={{ duration: 0.6, delay: index * 0.2 + 0.3 }}
                className="mt-10 -mx-4 relative lg:mt-0 lg:w-1/2"
              >
                <div className="relative space-y-6">
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="h-64 w-64 bg-blue-100 rounded-full opacity-20 blur-3xl" />
                    </div>
                    <img
                      className="relative rounded-lg shadow-lg object-cover"
                      src={feature.image}
                      alt={feature.title}
                    />
                  </div>
                </div>
              </motion.div>
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          transition={{ duration: 0.6, delay: 0.8 }}
          className="mt-20 text-center"
        >
          <a
            href="#contact"
            className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 md:py-4 md:text-lg md:px-10 transition duration-150 ease-in-out transform hover:scale-105"
          >
            Start Your Project
          </a>
        </motion.div>
      </div>
    </section>
  );
}

export default WhatWeDo;
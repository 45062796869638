import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyA0axoiCn44WcByOsDGu1xNM2EXGly-cfg",
    authDomain: "manhaja-31ed4.firebaseapp.com",
    databaseURL: "https://manhaja-31ed4.firebaseio.com",
    projectId: "manhaja-31ed4",
    storageBucket: "manhaja-31ed4.appspot.com",
    messagingSenderId: "876312254323",
    appId: "1:876312254323:web:2c7e18591aeb8cb0171706"
};
  
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore, Functions, and Storage
const db = getFirestore(app);
const functions = getFunctions(app);
const storage = getStorage(app);

// Export the initialized services
export { db, functions, storage };
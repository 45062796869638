import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import webAppIcon from '../assets/images/web-app.svg';
import mobileAppIcon from '../assets/images/mobile-app.svg';
import fullstackIcon from '../assets/images/fullstack.svg';
import innovationIcon from '../assets/images/innovation.svg';

const services = [
  {
    title: 'Web Applications',
    description: 'Custom web solutions that scale with your business needs, built using modern technologies and best practices.',
    icon: webAppIcon,
    color: 'blue',
  },
  {
    title: 'Mobile Development',
    description: 'Native and cross-platform mobile applications that provide seamless user experiences across all devices.',
    icon: mobileAppIcon,
    color: 'green',
  },
  {
    title: 'Full-Stack Solutions',
    description: 'End-to-end development services covering both frontend and backend, ensuring robust and scalable applications.',
    icon: fullstackIcon,
    color: 'purple',
  },
  {
    title: 'Innovation & AI',
    description: 'Cutting-edge solutions incorporating artificial intelligence and machine learning to drive business growth.',
    icon: innovationIcon,
    color: 'orange',
  },
];

const colorClasses = {
  blue: 'from-blue-500 to-blue-600',
  green: 'from-green-500 to-green-600',
  purple: 'from-purple-500 to-purple-600',
  orange: 'from-orange-500 to-orange-600',
};

function ServiceCard({ service, index }) {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      className="relative group"
    >
      <div className="relative z-10 h-full p-8 bg-white rounded-2xl shadow-xl transition-all duration-300 group-hover:shadow-2xl">
        <div className={`absolute inset-0 bg-gradient-to-r opacity-0 group-hover:opacity-5 rounded-2xl transition-opacity duration-300 ${colorClasses[service.color]}`} />
        
        <div className="relative">
          <div className="w-16 h-16 mb-8">
            <img src={service.icon} alt={service.title} className="w-full h-full object-contain" />
          </div>
          
          <h3 className="text-xl font-bold text-gray-900 mb-4">{service.title}</h3>
          <p className="text-gray-600 leading-relaxed">{service.description}</p>
          
          <motion.div 
            className="mt-6"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            <a 
              href="#contact" 
              className="inline-flex items-center text-sm font-medium text-blue-600 hover:text-blue-800 transition-colors duration-200"
            >
              Learn More
              <svg 
                className="ml-2 w-4 h-4" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M9 5l7 7-7 7" 
                />
              </svg>
            </a>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
}

function Services() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <section id="services" className="py-24 bg-gradient-to-b from-gray-50 to-white overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          transition={{ duration: 0.6 }}
          className="text-center max-w-3xl mx-auto mb-20"
        >
          <h2 className="text-4xl font-bold text-gray-900 sm:text-5xl">
            Our Services
          </h2>
          <p className="mt-6 text-xl text-gray-600">
            We provide comprehensive digital solutions to help your business thrive in the modern world.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {services.map((service, index) => (
            <ServiceCard key={index} service={service} index={index} />
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          transition={{ duration: 0.6, delay: 0.4 }}
          className="mt-20 text-center"
        >
          <a
            href="#contact"
            className="inline-flex items-center justify-center px-8 py-4 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 transition duration-150 ease-in-out transform hover:scale-105"
          >
            Start Your Project
          </a>
        </motion.div>
      </div>
    </section>
  );
}

export default Services;
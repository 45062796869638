import { useState, useCallback } from "react";
import { motion } from "framer-motion";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import { db, storage } from "../../firebase";
import { useDropzone } from "react-dropzone";

const generateSalt = (length = 16) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  return Array.from(crypto.getRandomValues(new Uint8Array(length)))
    .map((x) => chars[x % chars.length])
    .join('');
};

const validateEmail = (email) => {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(email);
};

const validateUrl = (url) => {
  if (!url) return true; // Optional field
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};

function JobApplicationModal({ job, onClose }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    resume: null,
    portfolio: "",
    github: ""
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const validateForm = () => {
    const errors = {};
    
    if (!formData.name.trim()) {
      errors.name = "Name is required";
    } else if (formData.name.trim().length < 2) {
      errors.name = "Name is too short";
    }

    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!validateEmail(formData.email)) {
      errors.email = "Invalid email address";
    }

    if (!formData.resume) {
      errors.resume = "Resume is required";
    }

    if (formData.portfolio && !validateUrl(formData.portfolio)) {
      errors.portfolio = "Invalid portfolio URL";
    }

    if (formData.github && !validateUrl(formData.github)) {
      errors.github = "Invalid GitHub URL";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setFormErrors(prev => ({
          ...prev,
          resume: "Resume file size must be less than 5MB"
        }));
        return;
      }
      const allowedTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
      if (!allowedTypes.includes(file.type)) {
        setFormErrors(prev => ({
          ...prev,
          resume: "Please upload a PDF or Word document"
        }));
        return;
      }
      setFormData(prev => ({ ...prev, resume: file }));
      setFormErrors(prev => ({ ...prev, resume: null }));
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"]
    },
    maxFiles: 1,
    multiple: false
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);
    setSubmitError(null);

    try {
      let resumePath = '';
      let resumeUrl = '';
      if (formData.resume) {
        const salt = generateSalt();
        const originalName = formData.resume.name.split('.').slice(0, -1).join('.');  // Get filename without extension
        const fileExtension = formData.resume.name.split('.').pop();
        const timestamp = Date.now();
        const secureFileName = `${originalName}-${timestamp}-${salt}.${fileExtension}`;
        const fileRef = ref(storage, `resumes/${secureFileName}`);
        
        // Upload file and get the response
        const uploadResult = await uploadBytes(fileRef, formData.resume);
        // Get the download URL from the upload result
        resumePath = uploadResult.ref.fullPath;
        resumeUrl = `https://firebasestorage.googleapis.com/v0/b/${uploadResult.ref.bucket}/o/${encodeURIComponent(resumePath)}?alt=media`;
      }

      const applicationData = {
        jobId: job.id,
        jobTitle: job.title,
        name: formData.name.trim(),
        email: formData.email.trim(),
        resumePath,
        resumeUrl,
        originalFileName: formData.resume ? formData.resume.name : '',  // Store original filename
        portfolio: formData.portfolio.trim(),
        github: formData.github.trim(),
        status: 'new',
        createdAt: serverTimestamp()
      };

      await addDoc(collection(db, 'jobApplications'), applicationData);
      setSubmitSuccess(true);
      
      setTimeout(() => {
        onClose();
      }, 3000);

    } catch (error) {
      console.error('Error submitting application:', error);
      setSubmitError(error.message || 'There was an error submitting your application. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    // Clear error when user starts typing
    if (formErrors[name]) {
      setFormErrors(prev => ({ ...prev, [name]: null }));
    }
  };

  return (
    <motion.div
      className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-50 p-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="bg-gray-900/95 rounded-xl p-8 max-w-2xl w-full max-h-[90vh] overflow-y-auto border border-white/10"
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
      >
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-2xl font-bold text-white">Apply for {job.title}</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white text-2xl"
            disabled={isSubmitting}
          >
            ×
          </button>
        </div>

        {submitSuccess ? (
          <div className="text-center py-8">
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ type: "spring", stiffness: 200, damping: 20 }}
              className="mb-4 text-green-500 text-6xl"
            >
              ✓
            </motion.div>
            <h3 className="text-xl font-semibold mb-2 text-white">Application Submitted!</h3>
            <p className="text-gray-300">
              Thank you for applying. We'll review your application and get back to you soon.
            </p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium mb-2 text-gray-300">Full Name</label>
              <input
                type="text"
                name="name"
                required
                className={`w-full bg-white/5 rounded-lg px-4 py-3 text-white border transition-colors ${
                  formErrors.name ? 'border-red-500' : 'border-white/10 focus:border-blue-500'
                } focus:ring-1 focus:ring-blue-500 outline-none`}
                value={formData.name}
                onChange={handleInputChange}
                disabled={isSubmitting}
              />
              {formErrors.name && (
                <p className="mt-1 text-sm text-red-500">{formErrors.name}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium mb-2 text-gray-300">Email</label>
              <input
                type="email"
                name="email"
                required
                className={`w-full bg-white/5 rounded-lg px-4 py-3 text-white border transition-colors ${
                  formErrors.email ? 'border-red-500' : 'border-white/10 focus:border-blue-500'
                } focus:ring-1 focus:ring-blue-500 outline-none`}
                value={formData.email}
                onChange={handleInputChange}
                disabled={isSubmitting}
              />
              {formErrors.email && (
                <p className="mt-1 text-sm text-red-500">{formErrors.email}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium mb-2 text-gray-300">Resume</label>
              <div
                {...getRootProps()}
                className={`w-full bg-white/5 rounded-lg px-4 py-8 border-2 border-dashed transition-colors cursor-pointer
                  ${isDragActive ? "border-blue-500 bg-blue-500/10" : formErrors.resume ? "border-red-500" : "border-white/10"}
                  ${formData.resume && !formErrors.resume ? "border-green-500 bg-green-500/10" : ""}
                `}
              >
                <input {...getInputProps()} />
                <div className="text-center">
                  {formData.resume ? (
                    <div className="text-green-500">
                      <p className="font-medium">✓ {formData.resume.name}</p>
                      <p className="text-sm text-gray-400 mt-1">Click or drag to replace</p>
                    </div>
                  ) : (
                    <div className="text-gray-400">
                      <p className="font-medium">Drop your resume here, or click to select</p>
                      <p className="text-sm mt-1">PDF or Word document (max 5MB)</p>
                    </div>
                  )}
                </div>
              </div>
              {formErrors.resume && (
                <p className="mt-1 text-sm text-red-500">{formErrors.resume}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium mb-2 text-gray-300">Portfolio URL (Optional)</label>
              <input
                type="url"
                name="portfolio"
                className={`w-full bg-white/5 rounded-lg px-4 py-3 text-white border transition-colors ${
                  formErrors.portfolio ? 'border-red-500' : 'border-white/10 focus:border-blue-500'
                } focus:ring-1 focus:ring-blue-500 outline-none`}
                value={formData.portfolio}
                onChange={handleInputChange}
                disabled={isSubmitting}
              />
              {formErrors.portfolio && (
                <p className="mt-1 text-sm text-red-500">{formErrors.portfolio}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium mb-2 text-gray-300">GitHub Profile (Optional)</label>
              <input
                type="url"
                name="github"
                className={`w-full bg-white/5 rounded-lg px-4 py-3 text-white border transition-colors ${
                  formErrors.github ? 'border-red-500' : 'border-white/10 focus:border-blue-500'
                } focus:ring-1 focus:ring-blue-500 outline-none`}
                value={formData.github}
                onChange={handleInputChange}
                disabled={isSubmitting}
              />
              {formErrors.github && (
                <p className="mt-1 text-sm text-red-500">{formErrors.github}</p>
              )}
            </div>

            {submitError && (
              <div className="text-red-500 text-sm p-3 bg-red-500/10 rounded-lg border border-red-500/20">
                {submitError}
              </div>
            )}

            <button
              type="submit"
              className="w-full bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed font-medium"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit Application"}
            </button>
          </form>
        )}
      </motion.div>
    </motion.div>
  );
}

export default JobApplicationModal;

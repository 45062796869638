import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { motion, useScroll } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Services from './components/Services';
import WhatWeDo from './components/WhatWeDo';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Careers from './pages/Careers';

function AnimatedSection({ children }) {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
      transition={{ duration: 0.6, ease: 'easeOut' }}
    >
      {children}
    </motion.div>
  );
}

function HomePage() {
  return (
    <main className="overflow-hidden">
      <Hero />
      
      <AnimatedSection>
        <About />
      </AnimatedSection>

      <AnimatedSection>
        <Services />
      </AnimatedSection>

      <AnimatedSection>
        <WhatWeDo />
      </AnimatedSection>

      <AnimatedSection>
        <Contact />
      </AnimatedSection>
    </main>
  );
}

function App() {
  const { scrollYProgress } = useScroll();

  return (
    <Router>
      <div className="min-h-screen bg-white">
        <motion.div
          className="fixed top-0 left-0 right-0 h-1 bg-blue-600 transform-origin-0 z-50"
          style={{ scaleX: scrollYProgress }}
        />
        
        <Header />
        
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/careers" element={<Careers />} />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
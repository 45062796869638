import { useState } from "react";
import { motion } from "framer-motion";
import JobApplicationModal from "./JobApplicationModal";

const positions = [
  {
    id: "frontend-dev",
    title: "Frontend Developer",
    type: "Full-time",
    location: "Kano, Nigeria (Hybrid)",
    experience: "2+ years",
    requirements: [
      "Strong proficiency in HTML5, CSS3, and modern JavaScript (ES6+)",
      "Expert knowledge of React.js, Vue.js, or Next.js",
      "Strong understanding of TypeScript and its best practices",
      "Experience with state management solutions (Redux, Vuex, or similar)",
      "Proficiency in responsive design and mobile-first development"
    ],
    responsibilities: [
      "Develop and maintain responsive web applications",
      "Write clean, maintainable, and efficient code",
      "Collaborate with designers to implement user interfaces",
      "Optimize applications for maximum speed and scalability",
      "Participate in code reviews and technical discussions"
    ]
  },
  {
    id: "mobile-dev",
    title: "Mobile Developer",
    type: "Full-time",
    location: "Kano, Nigeria (Hybrid)",
    experience: "2+ years",
    requirements: [
      "Strong experience in Android development using Java/Kotlin",
      "Proficiency in Flutter and Dart programming",
      "Knowledge of either React Native, Kotlin Multiplatform, or iOS development",
      "Experience with mobile app architecture patterns",
      "Strong understanding of mobile UI/UX principles"
    ],
    responsibilities: [
      "Design and build advanced mobile applications",
      "Ensure the performance and quality of applications",
      "Write clean, maintainable code with documentation",
      "Implement security best practices",
      "Collaborate with cross-functional teams"
    ]
  },
  {
    id: "intern",
    title: "Software Engineering Intern",
    type: "Internship",
    location: "Kano, Nigeria (On-site)",
    experience: "0-2 years",
    requirements: [
      "Currently pursuing a degree in Computer Science or related field",
      "Knowledge of programming languages (C, C++, Java, Kotlin, or Python)",
      "Basic understanding of data structures and algorithms",
      "Eagerness to learn and adapt to new technologies",
      "Good communication and teamwork abilities"
    ],
    responsibilities: [
      "Assist in developing and maintaining software applications",
      "Learn and apply modern development practices",
      "Participate in code reviews and technical discussions",
      "Contribute to documentation",
      "Work on real-world projects under guidance"
    ]
  }
];

export function JobListings() {
  const [selectedJob, setSelectedJob] = useState(null);
  const [viewingDetails, setViewingDetails] = useState(false);

  return (
    <div className="py-16">
      <div className="container mx-auto px-4">
        <motion.h1 
          className="text-4xl font-bold mb-12 text-center text-white"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          Open Positions
        </motion.h1>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {positions.map((position, index) => (
            <motion.div
              key={position.id}
              className="bg-white/10 backdrop-blur-lg rounded-xl p-8 hover:bg-white/15 transition-all duration-300 border border-white/10"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
            >
              <h2 className="text-2xl font-bold mb-4 text-white">{position.title}</h2>
              <div className="mb-6 space-y-2">
                <p className="text-blue-400">{position.type}</p>
                <p className="text-gray-300">{position.location}</p>
                <p className="text-gray-300">Experience: {position.experience}</p>
              </div>
              <div className="space-x-4">
                <button 
                  className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-lg transition-colors"
                  onClick={() => {
                    setSelectedJob(position);
                    setViewingDetails(false);
                  }}
                >
                  Apply Now
                </button>
                <button 
                  className="border border-blue-500 text-blue-400 hover:bg-blue-500/10 px-6 py-2 rounded-lg transition-colors"
                  onClick={() => {
                    setSelectedJob(position);
                    setViewingDetails(true);
                  }}
                >
                  View Details
                </button>
              </div>
            </motion.div>
          ))}
        </div>
      </div>

      {selectedJob && !viewingDetails && (
        <JobApplicationModal
          job={selectedJob}
          onClose={() => setSelectedJob(null)}
        />
      )}

      {viewingDetails && selectedJob && (
        <JobDetailsModal
          job={selectedJob}
          onClose={() => {
            setViewingDetails(false);
            setSelectedJob(null);
          }}
          onApply={() => {
            setViewingDetails(false);
          }}
        />
      )}
    </div>
  );
}

function JobDetailsModal({ job, onClose, onApply }) {
  return (
    <motion.div
      className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-50 p-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="bg-gray-900/95 rounded-xl p-8 max-w-4xl w-full max-h-[90vh] overflow-y-auto border border-white/10"
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
      >
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-3xl font-bold text-white">{job.title}</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white text-2xl"
          >
            ×
          </button>
        </div>

        <div className="space-y-8">
          <div className="bg-white/5 rounded-lg p-6">
            <h3 className="text-xl font-semibold mb-4 text-blue-400">Requirements</h3>
            <ul className="list-disc list-inside space-y-3 text-gray-300">
              {job.requirements.map((req, index) => (
                <li key={index}>{req}</li>
              ))}
            </ul>
          </div>

          <div className="bg-white/5 rounded-lg p-6">
            <h3 className="text-xl font-semibold mb-4 text-blue-400">Responsibilities</h3>
            <ul className="list-disc list-inside space-y-3 text-gray-300">
              {job.responsibilities.map((resp, index) => (
                <li key={index}>{resp}</li>
              ))}
            </ul>
          </div>

          <div className="flex justify-end space-x-4 pt-6">
            <button
              onClick={onClose}
              className="px-6 py-3 border border-gray-600 rounded-lg hover:bg-gray-800 transition-colors text-gray-300"
            >
              Close
            </button>
            <button
              onClick={onApply}
              className="px-6 py-3 bg-blue-500 hover:bg-blue-600 rounded-lg transition-colors text-white"
            >
              Apply Now
            </button>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}
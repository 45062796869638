import { JobListings } from "../components/Careers/JobListings";
import { AnimatedSection } from "../components/animations";

function Careers() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black">
      <AnimatedSection>
        <div className="container mx-auto px-4 py-24">
          <div className="text-center mb-16">
            <h1 className="text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-500">
              Build the Future with Us
            </h1>
            <p className="text-xl text-gray-300 max-w-2xl mx-auto leading-relaxed">
              Join our team of passionate developers and help us build innovative solutions
              that make a difference.
            </p>
          </div>
        </div>
      </AnimatedSection>

      <JobListings />
    </div>
  );
}

export default Careers;